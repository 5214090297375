// src/socket.ts
import { io, Socket } from 'socket.io-client';

let chatSocket: Socket | null = null;
let purchaseSocket: Socket | null = null;

export const initializePurchaseSocket = (onAuthError: () => void) => {
  if (!purchaseSocket) {
    purchaseSocket = io(`/purchase`, {
      withCredentials: true,
      path: '/socket.io',
      transports: ['websocket', 'polling'],
      autoConnect: false,
    });

    purchaseSocket.on('connect', () => {
      console.log(`✅ Connected to /purchase namespace with socket ID: ${purchaseSocket?.id}`);
    });

    purchaseSocket.on('authError', (data: any) => {
      console.error(`🔐 Purchase Socket Authentication Error: ${data.message}`);
      onAuthError();
    });

    purchaseSocket.on('disconnect', (reason: string) => {
      console.log(`🔌 Purchase Socket disconnected: ${reason}`);
    });

    purchaseSocket.on('error', (error: any) => {
      console.error(`❌ Purchase Socket error:`, error);
    });
  }
};

export const initializeChatSocket = (onAuthError: () => void) => {
  if (!chatSocket) {
    chatSocket = io(`/chat`, {
      withCredentials: true,
      path: '/socket.io',
      transports: ['websocket', 'polling'],
      autoConnect: false,
    });

    chatSocket.on('connect', () => {
      console.log(`✅ Connected to /chat namespace with socket ID: ${chatSocket?.id}`);
    });

    chatSocket.on('authError', (data: any) => {
      console.error(`🔐 Chat Socket Authentication Error: ${data.message}`);
      onAuthError();
    });

    chatSocket.on('disconnect', (reason: string) => {
      console.log(`🔌 Chat Socket disconnected: ${reason}`);
    });

    chatSocket.on('error', (error: any) => {
      console.error(`❌ Chat Socket error:`, error);
    });
  }
};



export const connectChatSocket = () => {
  chatSocket?.connect();
};

export const connectPurchaseSocket = () => {
  purchaseSocket?.connect();
};

export const disconnectChatSocket = () => {
  chatSocket?.disconnect();
};

export const disconnectPurchaseSocket = () => {
  purchaseSocket?.disconnect();
};

export const getChatSocket = (): Socket | null => {
  return chatSocket;
};

export const getPurchaseSocket = (): Socket | null => {
  return purchaseSocket;
};
