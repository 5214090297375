// src/SocketContext.tsx
import React, { createContext, useContext, useEffect } from 'react';
import { Socket } from 'socket.io-client';
import {
  initializeChatSocket,
  initializePurchaseSocket,
  connectChatSocket,
  connectPurchaseSocket,
  disconnectChatSocket,
  disconnectPurchaseSocket,
  getChatSocket,
  getPurchaseSocket,
} from './socket'; // Adjust the import path as needed
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import { logout } from 'redux/auth/authThunks';

interface SocketContextProps {
  chatSocket: Socket | null;
  purchaseSocket: Socket | null;
}

const SocketContext = createContext<SocketContextProps>({
  chatSocket: null,
  purchaseSocket: null,
});

interface SocketProviderProps {
  children: React.ReactNode;
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const redirectToLogin = () => {
      dispatch(logout())
        .catch((error: unknown) => {
          console.error('Logout failed:', error);
        });
    };

    if (isLoggedIn) {
      // Initialize all sockets
      initializeChatSocket(redirectToLogin);
      initializePurchaseSocket(redirectToLogin);

      // Connect all sockets
      connectChatSocket();
      connectPurchaseSocket();
    } else {
      // Disconnect all sockets
      disconnectChatSocket();
      disconnectPurchaseSocket();
    }

    return () => {
      // Clean up all sockets on unmount
      disconnectChatSocket();
      disconnectPurchaseSocket();
    };
  }, [isLoggedIn, dispatch]);

  const socketContextValue: SocketContextProps = {
    chatSocket: getChatSocket(),
    purchaseSocket: getPurchaseSocket(),
  };

  return (
    <SocketContext.Provider value={socketContextValue}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = () => {
  return useContext(SocketContext);
};
