// VerifyIdentity.tsx
import React, { useState, useEffect } from 'react';

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import VerificationStatus from 'pages/CartPage/VerificationStatus';
import { useSocketContext } from 'sockets/SocketContext'; 
import { useDispatch } from 'react-redux';
import { setUpdateIdentityRequireCheck } from 'redux/auth/authSlice';

interface VerifyIdentityProps {
  onComplete: () => void;
}

const VerifyIdentity: React.FC<VerifyIdentityProps> = ({ onComplete }) => {

  const { t } = useTranslation();
  const [verificationStatus, setverificationStatus] = useState<string>('processing_verification');
  const { purchaseSocket } = useSocketContext();
  const [errorMessage, setErrorMessage] = useState('');
  const [hasCompleted, setHasCompleted] = useState(false);
  const dispatch = useDispatch();

  const [verificationSecret, setVerificationSecret] = useState<string>('');
  const [verificationError, setVerificationError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (purchaseSocket) {
      // Define event handlers
      const handleOrderStatusUpdate = (data: { status: string; verificationSecret?: string; verificationError?: string }) => {
        setverificationStatus(data.status);
        setVerificationSecret(data.verificationSecret || '');
        setVerificationError(data.verificationError);
      };
      const handleOrderError = (error: { message: string }) => {
        console.error('Received orderError:', error);
        setErrorMessage(error.message);
        setverificationStatus('failed');
      };

      // Register event listeners
      purchaseSocket.on('orderStatusUpdate', handleOrderStatusUpdate);
      purchaseSocket.on('orderError', handleOrderError);
      // After handlers are registered, request the latest order status
      purchaseSocket.emit('requestOrderStatus');
      // Register event listeners
      purchaseSocket.on('noOrders', handleOrderStatusUpdate);
      purchaseSocket.on('orderError', handleOrderError);
      // After handlers are registered, request the latest order status
      purchaseSocket.emit('noOrders');

      // Cleanup event listeners on unmount
      return () => {
        purchaseSocket.off('orderStatusUpdate', handleOrderStatusUpdate);
        purchaseSocket.off('orderError', handleOrderError);
      };
    } 
  }, [purchaseSocket]);

  useEffect(() => {
    if (verificationStatus === 'verification_success' && !hasCompleted) {
      setHasCompleted(true);
      dispatch(setUpdateIdentityRequireCheck(false));
      onComplete();
    }
  }, [verificationStatus, hasCompleted, dispatch, onComplete]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <VerificationStatus
        verificationSecret={verificationSecret}
        verificationError={verificationError}
        paymentStatus={verificationStatus}
        fallbackMessage={'We detected unusual activity on your account. Please verify your identity to continue.'}
      />
      {errorMessage && (
        <Box sx={{ mt: 2, color: 'error.main' }}>
          {errorMessage}
        </Box>
      )}
    </Box>
  );
};

export default VerifyIdentity;
