import React, { useState, Suspense, createContext } from 'react';
import { IconButton, Box, Card, Typography, Fade, Tabs, Stack, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExpandMore, ChevronLeft } from '@mui/icons-material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ChatIcon from '@mui/icons-material/Chat';
import HomeIcon from '@mui/icons-material/Home';
import HelpIcon from '@mui/icons-material/Help';
import Animation from './Animation';
import HelpFAQ from './HelpFAQ';
import HelpMenu from './HelpMenu';
import { topicsData } from './topics/topicsData';
import TopicDetail from './topics/TopicDetail';
import ChatMenu from './ChatMenu';
import Chat from './Chat';
import NewChat from './NewChat';

const StyledTab = styled(Tab)(({ theme }) => ({
    flex: 1,
    minWidth: 0,
    textAlign: 'center',
    borderRadius: '13px',
    textTransform: 'capitalize',
    transition: 'background-color 0.3s ease',
    '&.Mui-selected': {
        backgroundColor: 'rgba(19, 27, 32, 0.6)',
        border: '1px solid rgba(54, 64, 73, 0.3)',
        color: 'white',
    },
    '&:not(.Mui-selected):hover': {
        backgroundColor: '#2b3238',
        color: 'white',
    },
}));

interface ChatContextProps {
    openChat: (topic?: string) => void;
}

export const ChatContext = createContext<ChatContextProps>({
    openChat: () => { },
});

const ChatBot: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [animationCompleted, setAnimationCompleted] = useState(false);
    const [selectedTopic, setSelectedTopic] = useState<string | null>(null);
    const [chatTopic, setChatTopic] = useState<string | null>(null);
    const [isHelpMenuVisible, setIsHelpMenuVisible] = useState<boolean>(true);
    const [isTopicContentVisible, setIsTopicContentVisible] = useState<boolean>(false);
    const [tabValue, setTabValue] = useState(0);

    const [isChatMenuVisible, setIsChatMenuVisible] = useState<boolean>(tabValue === 1);
    const [isChatVisible, setIsChatVisible] = useState<boolean>(false);
    const [isTabsVisible, setIsTabsVisible] = useState<boolean>(true);

    const TRANSITION_DURATION = 300;

    const toggleChat = () => {
        setOpen(!open);
        if (open) {
            setSelectedTopic(null);
            setChatTopic(null);
            setIsHelpMenuVisible(true);
            setIsTopicContentVisible(false);
            setTabValue(0);
            setIsChatMenuVisible(false);
            setIsChatVisible(false);
            setIsTabsVisible(true);
        }
    };

    const handleAnimationComplete = () => {
        setIsHelpMenuVisible(true);
        setAnimationCompleted(true);
    };

    const handleTopicSelect = (topicTitle: string) => {
        setIsHelpMenuVisible(false);
        setIsTabsVisible(false);
        setTimeout(() => {
            setSelectedTopic(topicTitle);
            setTimeout(() => {
                setIsTopicContentVisible(true);
            }, TRANSITION_DURATION);
        }, TRANSITION_DURATION);
    };

    // Modified handleBack function
    const handleBack = () => {
        if (selectedTopic) {
            setIsTopicContentVisible(false);
            setIsTabsVisible(true);
            setTimeout(() => {
                setSelectedTopic(null);
                setTimeout(() => {
                    if (tabValue === 0 || tabValue === 2) {
                        setIsHelpMenuVisible(true);
                    } else if (tabValue === 1) {
                        setIsChatMenuVisible(true);
                    }
                }, TRANSITION_DURATION);
            }, TRANSITION_DURATION);
        } else if (isChatVisible) {
            handleCloseChat();
        }
    };

    // Updated handleTabChange function
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);

        // Reset state variables
        setSelectedTopic(null);
        setIsTopicContentVisible(false);
        setIsChatVisible(false);
        setChatTopic(null);

        // Set visibility flags based on the new tab
        if (newValue === 0) {
            setIsHelpMenuVisible(true);
            setIsChatMenuVisible(false);
        } else if (newValue === 1) {
            setIsHelpMenuVisible(false);
            setIsChatMenuVisible(true);
        } else if (newValue === 2) {
            setIsHelpMenuVisible(true);
            setIsChatMenuVisible(false);
        }

        setIsTabsVisible(true);
    };

    // Function to handle opening Chat
    const handleOpenChat = (topic?: string) => {
        setChatTopic(topic || null);
        setSelectedTopic(null);
        setIsTopicContentVisible(false);
        setIsTabsVisible(false);
        setIsHelpMenuVisible(false);
        setIsChatMenuVisible(false);
        setTimeout(() => {
            setIsChatVisible(true);
        }, TRANSITION_DURATION);
    };

    // Function to handle closing Chat
    const handleCloseChat = () => {
        setIsChatVisible(false);
        setChatTopic(null);
        setTimeout(() => {
            setIsTabsVisible(true);
            if (tabValue === 0) {
                setIsHelpMenuVisible(true);
                setIsChatMenuVisible(false);
            } else if (tabValue === 1) {
                setIsHelpMenuVisible(false);
                setIsChatMenuVisible(true);
            } else if (tabValue === 2) {
                setIsHelpMenuVisible(true);
                setIsChatMenuVisible(false);
            }
        }, TRANSITION_DURATION);
    };

    const selectedTopicData = selectedTopic
        ? topicsData.find((topic) => topic.topic === selectedTopic) || null
        : null;

    return (
        <ChatContext.Provider value={{ openChat: handleOpenChat }}>
            <Card
                elevation={6}
                sx={{
                    position: 'fixed',
                    bottom: 20,
                    right: 20,
                    width: open ? (selectedTopic ? '600px' : '450px') : '70px',
                    height: open ? (selectedTopic ? '700px' : '650px') : '70px',
                    borderRadius: '25px 25px 0 25px',
                    display: 'flex',
                    flexDirection: 'column',
                    zIndex: 1000,
                    transition: 'all 0.3s ease',
                    overflow: 'hidden',
                }}
            >
                {/* Header */}
                <Box
                    sx={{
                        padding: open ? '8px 16px' : '8px',
                        backgroundColor: 'primary.main',
                        color: 'white',
                        display: 'flex',
                        justifyContent: open ? 'space-between' : 'center',
                        alignItems: 'center',
                        height: open ? 'auto' : '70px',
                        cursor: 'pointer',
                        transition: 'all 0.3s ease',
                    }}
                    onClick={() => {
                        if (!selectedTopic && !isChatVisible) toggleChat();
                    }}
                >
                    {selectedTopic ? (
                        <>
                            <IconButton
                                color="inherit"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleBack();
                                }}
                                sx={{ mr: 1 }}
                            >
                                <ChevronLeft />
                            </IconButton>
                            <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                {selectedTopic}
                            </Typography>
                        </>
                    ) : isChatVisible ? (
                        <>
                            <IconButton
                                color="inherit"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleBack();
                                }}
                                sx={{ mr: 1 }}
                            >
                                <ChevronLeft />
                            </IconButton>
                            <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                Talk with our AI agent Biggy
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Box sx={{ display: open ? 'flex' : 'none', alignItems: 'center' }}>
                                <SupportAgentIcon sx={{ mr: 1 }} />
                                <Typography variant="h6">Help Center</Typography>
                            </Box>
                            {!open && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                >
                                    <SupportAgentIcon />
                                </Box>
                            )}
                        </>
                    )}

                    {open && (
                        <IconButton color="inherit" onClick={toggleChat}>
                            <ExpandMore />
                        </IconButton>
                    )}
                </Box>

                {/* Chat content */}
                {open && (
                    <>
                        {/* Render Chat component if isChatVisible is true */}
                        {isChatVisible ? (
                            <Fade
                                in={isChatVisible}
                                timeout={TRANSITION_DURATION}
                                mountOnEnter
                                unmountOnExit
                            >
                                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <Chat onClose={handleCloseChat} topic={chatTopic} />
                                </Box>
                            </Fade>
                        ) : (
                            <>
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        padding: '16px',
                                        overflowY: 'auto',
                                        position: 'relative',
                                    }}
                                >
                                    {tabValue === 0 && (
                                        <>
                                            {!animationCompleted && (
                                                <Animation onAnimationComplete={handleAnimationComplete} />
                                            )}

                                            {animationCompleted && (
                                                <Fade
                                                    in={isHelpMenuVisible && !selectedTopic}
                                                    timeout={TRANSITION_DURATION}
                                                    mountOnEnter
                                                    unmountOnExit
                                                >
                                                    <Stack
                                                        direction={'column'}
                                                        spacing={2}
                                                        sx={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <HelpFAQ topics={topicsData} onTopicSelect={handleTopicSelect} />
                                                        <NewChat
                                                            title="Have a question?"
                                                            subheader="Talk with our AI agent or Support"
                                                            onClick={() => handleOpenChat()}
                                                        />
                                                    </Stack>
                                                </Fade>
                                            )}

                                            {selectedTopicData && (
                                                <Fade
                                                    in={isTopicContentVisible && selectedTopic !== null}
                                                    timeout={TRANSITION_DURATION}
                                                    mountOnEnter
                                                    unmountOnExit
                                                >
                                                    <Box
                                                        sx={{
                                                            textAlign: 'left',
                                                            p: 3,
                                                            width: '100%',
                                                            height: '100%',
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            overflowY: 'auto',
                                                        }}
                                                    >
                                                        <Suspense fallback={<Typography>Loading...</Typography>}>
                                                            <TopicDetail topicData={selectedTopicData} />
                                                        </Suspense>
                                                    </Box>
                                                </Fade>
                                            )}
                                        </>
                                    )}

                                    {tabValue === 1 && (
                                        <>
                                            {/* ChatMenu with Fade */}
                                            <Fade
                                                in={isChatMenuVisible}
                                                timeout={TRANSITION_DURATION}
                                                mountOnEnter
                                                unmountOnExit
                                            >
                                                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                                    <ChatMenu onAskQuestion={handleOpenChat} />
                                                </Box>
                                            </Fade>
                                        </>
                                    )}

                                    {tabValue === 2 && (
                                        <>
                                            <Fade
                                                in={isHelpMenuVisible && !selectedTopic}
                                                timeout={TRANSITION_DURATION}
                                                mountOnEnter
                                                unmountOnExit
                                            >
                                                <HelpMenu topics={topicsData} onTopicSelect={handleTopicSelect} />
                                            </Fade>

                                            {selectedTopicData && (
                                                <Fade
                                                    in={isTopicContentVisible && selectedTopic !== null}
                                                    timeout={TRANSITION_DURATION}
                                                    mountOnEnter
                                                    unmountOnExit
                                                >
                                                    <Box
                                                        sx={{
                                                            textAlign: 'left',
                                                            p: 3,
                                                            width: '100%',
                                                            height: '100%',
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            overflowY: 'auto',
                                                        }}
                                                    >
                                                        <Suspense fallback={<Typography>Loading...</Typography>}>
                                                            <TopicDetail topicData={selectedTopicData} />
                                                        </Suspense>
                                                    </Box>
                                                </Fade>
                                            )}
                                        </>
                                    )}
                                </Box>

                                {/* Tabs with Fade */}
                                {isTabsVisible && (
                                    <Fade
                                        in={isTabsVisible}
                                        timeout={TRANSITION_DURATION}
                                        mountOnEnter
                                        unmountOnExit
                                    >
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <Tabs
                                                value={tabValue}
                                                onChange={handleTabChange}
                                                aria-label="ChatBot Tabs"
                                                variant="fullWidth"
                                                TabIndicatorProps={{ style: { display: 'none' } }}
                                                sx={{
                                                    px: '16px',
                                                    pb: 2,
                                                    '& .MuiTabs-flexContainer': {
                                                        gap: '11px',
                                                    },
                                                }}
                                            >
                                                <StyledTab label="Home" icon={<HomeIcon sx={{ fontSize: 'large' }} />} />
                                                <StyledTab label="Messages" icon={<ChatIcon sx={{ fontSize: 'large' }} />} />
                                                <StyledTab label="Help" icon={<HelpIcon sx={{ fontSize: 'large' }} />} />
                                            </Tabs>
                                        </Box>
                                    </Fade>
                                )}
                            </>
                        )}
                    </>
                )}
            </Card>
        </ChatContext.Provider>
    );
};

export default ChatBot;
