import React, { useState } from 'react';
import { List, ListItem, ListItemText, Typography, Box, Button, Divider } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// Define the structure for chat history items
interface ChatHistoryItem {
    category: string;
    topic: string;
    content: string;
  }
  
  interface ChatMenuProps {
    onAskQuestion: () => void;
  }


const ChatMenu: React.FC<ChatMenuProps> = ({ onAskQuestion }) => {
    const [chatHistory, setChatHistory] = useState<ChatHistoryItem[]>([
        {
            category: 'Billing',
            topic: 'Invoice Inquiry',
            content: 'I need help with my invoice for the last purchase.',
        },
        {
            category: 'Technical Support',
            topic: 'Login Issues',
            content: 'I cannot log into my account using my credentials.',
        },
        {
            category: 'Product Information',
            topic: 'Feature Details',
            content: 'Can you provide more information about the new features?',
        },
        // Add more dummy data as needed
    ]);

    return (
        <>
            {chatHistory.length > 0 ? (
                <List sx={{ flexGrow: 1, overflowY: 'auto' }}>
                    {chatHistory.map((item, index) => (
                        <React.Fragment key={index}>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary={
                                        <>
                                            <Typography variant="subtitle1" fontWeight="bold">
                                                {item.topic}
                                            </Typography>
                                            <Typography variant="body2" color="text.primary">
                                                {item.content}
                                            </Typography>
                                        </>
                                    }
                                />
                                <ChevronRightIcon sx={{ margin: 'auto' }} />
                            </ListItem>
                            {index < chatHistory.length - 1 && <Divider component="li" />}
                        </React.Fragment>
                    ))}
                </List>
            ) : (
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="body1" gutterBottom>
                        There are no messages.
                    </Typography>
                </Box>
            )}
            <Button
                variant="contained"
                color="primary"
                onClick={() => onAskQuestion()}
            >
                Ask a question
            </Button>
        </>
    );
};

export default ChatMenu;
