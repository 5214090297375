// Payment.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from 'redux/store';
import { createPaymentIntent } from 'redux/payment/paymentThunks';
import { useNavigate } from 'react-router-dom';

import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { Box, Typography, Button, Container } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

import PaymentForm from './StripeForm';
import PaymentSkeleton from './Components/PaymentSkeleton';
import ManualReview from './ManualReview';
import { useTranslation } from 'react-i18next';
import VerificationStatus from './VerificationStatus';
import { useSocketContext } from 'sockets/SocketContext'; // Adjust the import path as needed

interface PaymentProps {
    onBackToCart: () => void;
}

const stripeKey = (import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || '').trim();

const stripePromise: Promise<Stripe | null> = loadStripe(stripeKey);

const Payment = ({ onBackToCart }: PaymentProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [isManualReview, setIsManualReview] = useState(false);
    const [isIdentityReview, setIsIdentityReview] = useState(false);
    const [verificationSecret, setVerificationUrl] = useState<string | undefined>(undefined);
    const [verificationError, setVerificationError] = useState<string | undefined>(undefined);

    const clientSecret = useSelector((state: RootState) => state.payment.clientSecret);
    const paymentError = useSelector((state: RootState) => state.payment.error);
    const paymentId = useSelector((state: RootState) => state.payment.paymentId);

    const cartItems = useSelector((state: RootState) => state.cart.items);
    const totalAmount = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

    // New state to hold the current paymentId
    const [currentPaymentId, setCurrentPaymentId] = useState<string | null>(null);

    const appearance = {
        labels: 'floating' as const,
        variables: {
            colorPrimary: '#ffff',
            colorBackground: '#0e1416',
            colorText: '#ffff',
            colorDanger: '#df1b41',
            fontFamily: 'Inter, system-ui, sans-serif',
            borderRadius: '10px',
        }
    };

    useEffect(() => {
        if (totalAmount > 0) {
            if (totalAmount >= 0.50) {
                dispatch(createPaymentIntent({ amount: totalAmount, items: cartItems }));
            } else {
                alert("Minimum payment amount is CHF 0.50");
            }
        }
    }, [totalAmount, dispatch]);

    const { purchaseSocket } = useSocketContext();

    // Listen for 'orderStatusUpdate' from the server
    useEffect(() => {
        if (purchaseSocket) {
            const handleOrderStatusUpdate = (data: { paymentId: string; status: string; verificationSecret?: string; verificationError?: string }) => {
                if (data.status === 'manual_review' || data.verificationError === 'manual_review') {
                    setIsManualReview(true);
                } else if (data.status === 'pending_verification') {
                    setIsIdentityReview(true);
                    setVerificationUrl(data.verificationSecret); 
                    setVerificationError(data.verificationError);
                }
            };

            purchaseSocket.on('orderStatusUpdate', handleOrderStatusUpdate);

            return () => {
                purchaseSocket.off('orderStatusUpdate', handleOrderStatusUpdate);
            };
        }
    }, [purchaseSocket, currentPaymentId, paymentId]);

    // Render conditions
    if (isManualReview) {
        return <ManualReview />;
    }

    if (isIdentityReview && verificationSecret) {
        return <VerificationStatus verificationSecret={verificationSecret} verificationError={verificationError} />;
    }

    if (paymentError === 'Too many failed requests') {
        return (
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <img src="/img/payment_block.webp" height={250} alt="Empty cart" />
                <Typography variant="h4" sx={{ mb: 3 }}>Too many failed requests</Typography>
                <Typography variant="h6" sx={{ mb: 3 }}>Please try again later on, in the meanwhile explore our catalog and add some favorites.</Typography>
                <Button startIcon={<ArrowBackRoundedIcon />} variant="contained" color="secondary" onClick={() => navigate('/')}>
                    Return Home
                </Button>
            </Container>
        );
    }

    if (!clientSecret) {
        return <PaymentSkeleton />;
    }

    return (
        <Box>
            <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
                <PaymentForm clientSecret={clientSecret} onBackToCart={onBackToCart} />
            </Elements>
        </Box>
    );
};

export default Payment;
