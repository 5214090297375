// File: src/redux/applicationSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ApplicationState {
  selectedCurrency: string;
  selectedCurrencySymbol: string;
  selectedRegion: string;
  userIp: string | null;
  realCountryHeader: string | null; 
  isLoading: boolean;
  locationStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  isOnline: boolean;
}

/**
 * The initial slice state
 */
const initialState: ApplicationState = {
  selectedCurrency: 'EUR',
  selectedCurrencySymbol: '€',
  selectedRegion: 'GLBL',
  userIp: null,
  realCountryHeader: null,
  isLoading: false,
  locationStatus: 'idle',
  error: null,
  isOnline: typeof navigator !== 'undefined' ? navigator.onLine : true,
};


const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setSelectedCurrency(state, action: PayloadAction<string>) {
      state.selectedCurrency = action.payload;
    },
    setSelectedCurrencySymbol(state, action: PayloadAction<string>) {
      state.selectedCurrencySymbol = action.payload;
    },
    setCurrencyAndRegion(
      state,
      action: PayloadAction<{ currency: string; region: string }>
    ) {
      state.selectedCurrency = action.payload.currency;
      state.selectedRegion = action.payload.region;
      state.selectedCurrencySymbol = 
      action.payload.currency === 'EUR' ? '€' :
      action.payload.currency === 'USD' ? '$' :
      action.payload.currency === 'GBP' ? '£' :
      action.payload.currency === 'CHF' ? 'CHF' :
      '€'; 
    },
    setUserIp(state, action: PayloadAction<string>) {
      state.userIp = action.payload;
    },
    setOnlineStatus(state, action: PayloadAction<boolean>) {
      state.isOnline = action.payload;
    },
  },

});

export const {
  setSelectedCurrency,
  setSelectedCurrencySymbol,
  setCurrencyAndRegion,
  setUserIp,
  setOnlineStatus,
} = applicationSlice.actions;

export default applicationSlice.reducer;