// File: src/redux/store.ts

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
// Instead of localStorage, we use session storage:
import sessionStorage from 'redux-persist/lib/storage/session';

import searchReducer from './reducers/searchReducer';
import authReducer from './auth/authSlice';
import cartReducer from './reducers/cartReducer';
import paymentReducer from './payment/paymentReducer';
import applicationReducer from './services/applicationReducer';
import keyReducer from './key/keysReducer';
import { productsApi } from './RTK/productsApi';
import { optionsApi } from './RTK/optionsApi';

// Define the app version (can be manually updated during new releases)
const CURRENT_VERSION = 4;

// 1) Combine your reducers
const rootReducer = combineReducers({
  search: searchReducer,
  keys: keyReducer,
  auth: authReducer,
  cart: cartReducer,
  payment: paymentReducer,
  application: applicationReducer,
  [productsApi.reducerPath]: productsApi.reducer,
  [optionsApi.reducerPath]: optionsApi.reducer,
});

// 2) Setup persist config
const persistConfig = {
  key: 'root',
  version: CURRENT_VERSION,
  // Use session storage instead of local storage:
  storage: sessionStorage,
  whitelist: ['auth', 'cart', 'payment', 'application'],
  // Optional migration logic
  migrate: async (state: any) => {
    const storedVersion = state?._persist?.version || 0;
    if (storedVersion < CURRENT_VERSION) {
      // Purge the store if version changed
      return Promise.resolve(undefined);
    }
    return Promise.resolve(state);
  },
};

// 3) Wrap the rootReducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// 4) Create the store
const isDevelopment = import.meta.env.MODE === 'development';

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // If you ignore certain action types, list them here
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          'auth/logout/fulfilled',
          'auth/logout/rejected',
          'auth/logout/pending',
        ],
      },
    })
    .concat(productsApi.middleware)
    .concat(optionsApi.middleware),
  devTools: isDevelopment,
});

// 5) Export the persistor
export const persistor = persistStore(store);

// 6) Export types
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;