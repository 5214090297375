import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button, Typography, Card, Divider, Link } from '@mui/material';
import { login, authMe } from 'redux/auth/authThunks';
import { addFavorite } from 'redux/auth/updateUserThunks';
import { RootState, AppDispatch } from 'redux/store';
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';

import { useFetchProductByIdQuery } from 'redux/RTK/productsApi';

import { makeUrlSafe, getPlatformName, getTypeName } from 'utils/getNames';
import GoogleLoginButton from './SocialLogin/GoogleButton';
import { useFetchPlatformsQuery, useFetchTypesQuery } from 'redux/RTK/optionsApi';

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();

  // Grab standard data from RTK
  const { data: platforms = [] } = useFetchPlatformsQuery();
  const { data: types = [] } = useFetchTypesQuery();

  // Grab auth state from Redux
  const { isLoading, error, isLoggedIn, isGoogleLoading } = useSelector(
    (state: RootState) => state.auth
  );

  // 1) Parse URL params (productId & platformGroupId)
  const searchParams = new URLSearchParams(location.search);
  const productId = searchParams.get('productId');
  const platformGroupId = searchParams.get('platformGroupId');

  // 2) Conditionally fetch the product via RTK Query
  //    We only skip if there's no productId or the user is not logged in yet
  const {
    data: fetchedProduct,
    isLoading: isProductLoading,
    isSuccess: isProductSuccess,
    isError: isProductError,
  } = useFetchProductByIdQuery(productId ?? '', {
    skip: !productId || !isLoggedIn,
  });

  // 3) If Google logs in automatically
  useEffect(() => {
    if (isGoogleLoading) {
      dispatch(authMe())
        .unwrap()
        .then(() => {
          if (isLoggedIn) {
            handlePostLoginRedirection();
          }
        })
        .catch((error) => {
          console.error('Error during Google login authentication:', error);
        });
    }
  }, [dispatch, isLoading, isLoggedIn, isGoogleLoading, location.search]);

  // 4) On normal login form submit
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const trimmedEmail = email.trim();

    dispatch(login({ email: trimmedEmail, password })).then((action: any) => {
      if (login.fulfilled.match(action)) {
        handlePostLoginRedirection();
      } else {
        console.error('Login failed:', action.payload);
      }
    });
  };

  /**
   * 5) Called after login or google flow is done. 
   *    We'll check if we should redirect to the product page or dashboard.
   */
  const handlePostLoginRedirection = async () => {
    // If user came from cart, go to /cart/payment
    if (location.pathname === '/cart/login') {
      navigate('/cart/payment');
      return;
    }

    // If we have productId & platformGroupId => we'll wait for the RTK query
    if (productId && platformGroupId) {
      // If product is still loading or we never triggered the query,
      // you can wait or handle an immediate redirect with a loading UI.
      if (isProductLoading) {
        return;
      }
      if (isProductError || !fetchedProduct) {
        console.error('Failed or no product found in RTK. Navigating to /dashboard.');
        navigate('/dashboard', { replace: true });
        return;
      }

      // If success => find the platform
      const platform = fetchedProduct.platforms.find(
        (p: any) => p._id === platformGroupId
      );
      if (!platform) {
        console.error('Platform not found in product data');
        navigate('/dashboard', { replace: true });
        return;
      }

      const category = platform.category;
      const typeName = makeUrlSafe(getTypeName(fetchedProduct.type, types));
      const productName = makeUrlSafe(fetchedProduct.title);
      const productPlatform = getPlatformName(platform.platformId, platforms);

      // addFavorite
      await dispatch(addFavorite({ productId, platformGroupId }));
      navigate(`/${typeName}/${category}/${productPlatform}/${productName}`, {
        replace: true,
      });
      return;
    }

    // else => normal case
    navigate('/dashboard');
  };

  return (
    <Card elevation={6} sx={{ padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography component="h1" variant="h5">Login</Typography>

      <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: 8 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Email Address"
          name="email"
          size="small"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          size="small"
          name="password"
          label="Password"
          type="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && <Typography color="error">{error}</Typography>}
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={isLoading}>
          Sign In
        </Button>
      </form>

      <Link component={RouterLink} to={{ pathname: "/register", search: location.search }}>
        Don't have an account? Sign up
      </Link>

      <Divider sx={{ my: 2, color: 'text.primary' }} />

      <GoogleLoginButton />
    </Card>
  );
};

export default LoginForm;
