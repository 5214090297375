import React, { useState, useEffect } from 'react';
import { Box, Stack, TextField, Button } from '@mui/material';
import { Send } from '@mui/icons-material';
import { useSocketContext } from 'sockets/SocketContext';
import MessageBubble from './MessageBubble';

type Sender = 'user' | 'AI';

interface Message {
  text: string;
  sender: Sender;
}

interface ChatProps {
  onClose: () => void;
  topic?: string | null;
}

const Chat: React.FC<ChatProps> = ({ onClose, topic }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState('');
  const { chatSocket } = useSocketContext();

  const handleSendMessage = () => {
    if (inputValue.trim() && chatSocket) {
      const userMessage: Message = { text: inputValue, sender: 'user' };
      setMessages((prev) => [...prev, userMessage]);

      // Emit the message to the backend
      chatSocket.emit('sendMessage', inputValue);

      setInputValue('');
    }
  };

  useEffect(() => {
    if (topic) {
      // Handle the topic (e.g., send it as the first message)
      const topicMessage: Message = { text: topic, sender: 'user' };
      setMessages((prev) => [...prev, topicMessage]);
    }
  }, [topic]);

  useEffect(() => {
    if (!chatSocket) return;

    // Listen for incoming messages
    const handleReceiveMessage = (data: { sender: string; message: string; timestamp: string }) => {
        // Ensure 'sender' is 'AI'
        const aiMessage: Message = { text: data.message, sender: 'AI' };
        setMessages((prev) => [...prev, aiMessage]);
    };

    const handleErrorMessage = (data: { message: string }) => {
        // Treat error messages as coming from 'AI'
        const errorMessage: Message = { text: data.message, sender: 'AI' };
        setMessages((prev) => [...prev, errorMessage]);
    };

    chatSocket.on('receiveMessage', handleReceiveMessage);
    chatSocket.on('errorMessage', handleErrorMessage);

    // Optional: Listen for connection events
    chatSocket.on('connect', () => {
        console.log('Connected to /chat namespace');
    });

    chatSocket.on('disconnect', (reason) => {
        console.log(`Disconnected from /chat namespace: ${reason}`);
    });

    chatSocket.on('connect_error', (error) => {
        console.error('Connection error:', error);
    });

    // Cleanup listeners on unmount
    return () => {
        chatSocket.off('receiveMessage', handleReceiveMessage);
        chatSocket.off('errorMessage', handleErrorMessage);
        chatSocket.off('connect');
        chatSocket.off('disconnect');
        chatSocket.off('connect_error');
    };
  }, [chatSocket]);

  return (
    <>

      {/* Chat content */}
      <Box
        sx={{
          flexGrow: 1,
          padding: '16px',
          overflowY: 'auto',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column-reverse',
          height: '100%',
        }}
      >
        {/* Messages */}
        <Box sx={{ zIndex: 10 }}>
          {messages.map((message, index) => (
            <MessageBubble key={index} message={message} />
          ))}
        </Box>
      </Box>

      {/* Message Input */}
      <Stack
        direction="row"
        sx={{
          padding: '16px',
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          size="medium"
          placeholder="Type a message..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSendMessage();
            }
          }}
        />
        <Button
          variant="contained"
          color="primary"
          size="medium"
          sx={{ width: '30px', ml: 2 }}
          endIcon={<Send />}
          onClick={handleSendMessage}
          fullWidth
        />
      </Stack>
    </>
  );
};

export default Chat;
