// src/components/MessageBubble.tsx

import React, { useState } from 'react';
import { Box, Typography, Avatar } from '@mui/material';

// Define the list of user avatars
const avatars = [
    '/img/avatar-1.webp',
    '/img/avatar-2.webp',
    // Add more avatar paths as needed
];

// Function to get a random avatar from the avatars array
const getRandomAvatar = () => {
    const randomIndex = Math.floor(Math.random() * avatars.length);
    return avatars[randomIndex];
};

// Module-level variable to store the user's avatar URL
let userAvatarUrl = getRandomAvatar();

interface MessageBubbleProps {
    message: {
        text: string;
        sender: 'user' | 'AI';
        timestamp?: string;
    };
}

const MessageBubble: React.FC<MessageBubbleProps> = ({ message }) => {
    const isUser = message.sender === 'user';

    // State to manage the avatar URL (only for user messages)
    const [avatarUrl, setAvatarUrl] = useState(isUser ? userAvatarUrl : '');

    // Handler for image load errors to select a new random avatar
    const handleImageError = () => {
        if (isUser) {
            const newAvatar = getRandomAvatar();
            setAvatarUrl(newAvatar);
            userAvatarUrl = newAvatar; // Update the module-level variable to maintain consistency
        }
    };

    return (
        <Box
            display="flex"
            justifyContent={isUser ? 'flex-end' : 'flex-start'}
            alignItems="flex-start"
            mb={1}
        >


            {/* Message Bubble */}
            <Box
                sx={{
                    maxWidth: '70%',
                    backgroundColor: isUser ? 'primary.main' : 'grey.300',
                    color: isUser ? 'primary.contrastText' : 'black',
                    padding: '10px 15px',
                    borderRadius: isUser
                        ? '15px 15px 0 15px' // Top-left, Top-right, Bottom-right, Bottom-left
                        : '15px 15px 15px 0',
                    boxShadow: 1,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {/* AI Avatar on the Left */}
                {!isUser && (
                    <Avatar
                        sx={{
                            bgcolor: 'grey.500',
                            mr: 1,
                            width: 40,
                            height: 40,
                            fontSize: '1.25rem',
                        }}
                    >
                        🤖
                    </Avatar>
                )}
                <Typography variant="body1">
                    {message.text}
                </Typography>
                {message.timestamp && (
                    <Typography
                        variant="caption"
                        sx={{
                            display: 'block',
                            textAlign: isUser ? 'right' : 'left',
                            mt: 0.5,
                        }}
                    >
                        {new Date(message.timestamp).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                        })}
                    </Typography>
                )}

                {/* User Avatar on the Right */}
                {isUser && (
                    <Avatar
                        sx={{
                            bgcolor: 'primary.dark',
                            ml: 1,
                            width: 40,
                            height: 40,
                        }}
                        src={avatarUrl}
                        onError={handleImageError}
                    >
                        U
                    </Avatar>
                )}
            </Box>


        </Box>
    );
};

export default MessageBubble;
