// File: src/pages/ProductsPage/ProductsPage.tsx

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { RootState, AppDispatch } from 'redux/store';
// Optional: if you keep these filter actions in your slice for UI reasons
import { resetFilters, setFilter } from 'redux/auth/authSlice';
import { FilterValues } from 'redux/types/ProductsTypes';

// Import the RTK Query API
import { productsApi } from 'redux/RTK/productsApi';
import { Product } from 'redux/types/ProductsTypes';
import { 
  useFetchCategoriesQuery, 
  useFetchPlatformsQuery, 
  useFetchTypesQuery,
  useFetchGenresQuery,
} from 'redux/RTK/optionsApi';


import { Grid2 as Grid, Button, Container, Avatar, Typography, Chip, Box } from '@mui/material';
import TagIcon from '@mui/icons-material/Tag';
import SearchIcon from '@mui/icons-material/Search';

import ProductFilter from 'pages/ProductsPage/Components/ProductFilter';
import ProductGrid from 'pages/ProductsPage/Components/ProductGrid';
import LoadingGrid from 'pages/ProductsPage/Components/LoadingGrid';
import noProductImage from 'assets/noProduct.webp';
import PlatformLogo from 'components/icons/PlatformLogos';
import CategoryLogosMap from 'components/icons/categoryLogosMap';

export default function ProductsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();

  // 1) Extract query params
  const queryParams = new URLSearchParams(location.search);
  const platformId = queryParams.get('platform') || '';
  const typeId = queryParams.get('type') || '';
  const genreId = queryParams.get('genre') || '';
  const categoryId = queryParams.get('category') || '';
  const maxPrice = queryParams.get('maxPrice') || '';
  const searchQuery = queryParams.get('search') || '';

  // 2) We assume you still keep a slice 'product' for UI filter states:
  const { filter } = useSelector((state: RootState) => state.auth);

  const { data: platforms = [] } = useFetchPlatformsQuery();
  const { data: categories = [] } = useFetchCategoriesQuery();
  const { data: types = [] } = useFetchTypesQuery();
  const { data: genres = [] } = useFetchGenresQuery();

  // 4) Instead of dispatching fetchProducts, we call `useFetchProductsQuery()`
  //    Build a "filters" object from your query params
  const { data: productEntityState, error, isFetching, isError } =
    productsApi.useFetchProductsQuery({
      searchQuery,
      filters: {
        platform: platformId,
        type: typeId,
        genre: genreId,
        category: categoryId,
        maxPrice: maxPrice,
      },
    });

  // 5) Convert the entity state into a local array of `Product`
  const products: Product[] = React.useMemo(() => {
    if (!productEntityState) return [];
    // We stored them in normalized form: { ids: [...], entities: {...} }
    // Convert to an array:
    return productEntityState.ids
      .map((id) => productEntityState.entities[id])
      .filter((p): p is Product => Boolean(p));
  }, [productEntityState]);

  // 6) Keep your effect to set slice-based UI filters from the query params if you wish
  useEffect(() => {
    if (platformId) {
      dispatch(setFilter({ key: 'platform', value: platformId }));
    }
    if (typeId) {
      dispatch(setFilter({ key: 'type', value: typeId }));
    }
    if (genreId) {
      dispatch(setFilter({ key: 'genre', value: genreId }));
    }
    if (categoryId) {
      dispatch(setFilter({ key: 'category', value: categoryId }));
    }
    if (maxPrice) {
      dispatch(setFilter({ key: 'maxPrice', value: maxPrice }));
    }
    // We no longer dispatch any fetchProducts thunk; the RTK Query handles that.
  }, [dispatch, platformId, typeId, genreId, categoryId, maxPrice]);

  // 7) Filter-handling logic (chips, remove filter, etc.)
  const handleResetFilters = () => {
    dispatch(resetFilters());
    navigate('/products');
  };

  const handleDeleteChip = (key: keyof FilterValues) => {
    // Remove from your Redux UI filter
    dispatch(setFilter({ key, value: '' }));
    // Also remove from query params
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.delete(key);
    navigate(`${location.pathname}?${newSearchParams.toString()}`, { replace: true });
  };

  const handleDeleteSearchQuery = () => {
    // If you have a slice state for search, remove that
    dispatch({ type: 'SET_SEARCH_QUERY', payload: '' });
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.delete('search');
    navigate(`${location.pathname}?${newSearchParams.toString()}`, { replace: true });
  };

  const activeFilters = [
    { key: 'category', value: categoryId, label: categories.find(c => c._id === categoryId)?.name || categoryId },
    { key: 'platform', value: platformId, label: platforms.find(p => p._id === platformId)?.name || platformId },
    { key: 'type', value: typeId, label: types.find(t => t._id === typeId)?.name || typeId },
    { key: 'genre', value: genreId, label: genres.find(g => g._id === genreId)?.name || genreId },
    { key: 'maxPrice', value: maxPrice, label: `${t('maxPrice')}: ${maxPrice}` },
  ].filter(filter => filter.value);

  const renderAvatar = (filterKey: string, filterValue: string) => {
    if (filterKey === 'platform') {
      return <PlatformLogo platformId={filterValue} />;
    } else if (filterKey === 'category') {
      const IconComponent = CategoryLogosMap[filterValue];
      if (IconComponent) {
        return (
          <Avatar>
            <IconComponent />
          </Avatar>
        );
      }
    } else if (filterKey === 'genre') {
      return <TagIcon fontSize="small" />;
    }
    return null;
  };

  return (
    <Container sx={{ py: { xs: 8, sm: 14 } }}>
      {/* 
        Render your filter UI. 
        It's still referencing your local store slice for filter states, 
        but the actual fetch is from useFetchProductsQuery above.
      */}
      <ProductFilter
        filter={{ ...filter, platform: platformId, maxPrice }}
        categories={categories}
        platforms={platforms}
        maxPrice={maxPrice}
        types={types}
        genres={genres}
      />

      {/* Active Filters */}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 4 }}>
        {activeFilters.length > 0 && (
          <Chip
            label={t('resetFilters')}
            onClick={handleResetFilters}
            sx={{ m: 0.5 }}
            color="primary"
          />
        )}
        {activeFilters.map((af) => {
          const avatar = renderAvatar(af.key, af.value);
          return (
            <Chip
              key={af.key}
              avatar={avatar ?? undefined}
              label={af.label}
              onDelete={() => handleDeleteChip(af.key as keyof FilterValues)}
              sx={{ m: 0.5 }}
            />
          );
        })}
        {searchQuery && (
          <Chip
            avatar={<SearchIcon />}
            label={`Search: ${searchQuery}`}
            onDelete={handleDeleteSearchQuery}
            sx={{ m: 0.5 }}
          />
        )}
      </Box>

      {/* Content: If fetching => Loading, If error => show error, else => show products */}
      {isFetching ? (
        <LoadingGrid />
      ) : isError ? (
        <Typography color="error">Error: {String(error)}</Typography>
      ) : (
        <>
          {products.length > 0 ? (
            <ProductGrid products={products} filter={filter} searchQuery={searchQuery} />
          ) : (
            <Grid
              size={{ xs: 12 }}
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="23px"
            >
              <img src={noProductImage} alt="No products" />
              <Typography variant="h3">{t('noProductsFound')}</Typography>
              <Typography variant="h6">{t('tryDifferentQuery')}</Typography>
              <Button variant="contained" color="primary" onClick={handleResetFilters}>
                {t('resetFilters')}
              </Button>
            </Grid>
          )}
        </>
      )}
    </Container>
  );
}