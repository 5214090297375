import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import { clearCart } from 'redux/reducers/cartReducer';
import { createPaymentIntent } from 'redux/payment/paymentThunks';
import { clearPaymentData } from 'redux/payment/paymentReducer';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, CircularProgress, Container } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import VerificationStatus from './VerificationStatus';
import { useSocketContext } from 'sockets/SocketContext'; // Adjust the import path as needed
import ManualReview from './ManualReview';

const PaymentStatus: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const paymentId = useSelector((state: RootState) => state.payment.paymentId);
  const [paymentStatus, setPaymentStatus] = useState<string>('processing');
  const paymentError = useSelector((state: RootState) => state.payment.error);
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const cartTotal = useSelector((state: RootState) => state.cart.total);
  const { purchaseSocket } = useSocketContext();

  const [errorMessage, setErrorMessage] = useState('');
  const [verificationSecret, setVerificationUrl] = useState<string | null>(null);
  const [verificationError, setVerificationError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (purchaseSocket && paymentId) {
      // Join the order room
      purchaseSocket.emit('requestOrderStatus');
      // Define event handlers
      const handleOrderStatusUpdate = (data: { paymentId: string; status: string; verificationSecret?: string; verificationError?: string }) => {
        setPaymentStatus(data.status);
        if (data.status === 'pending_verification' || data.status === 'processing_verification') {
          if (data.verificationSecret) {
            setVerificationUrl(data.verificationSecret);
            setVerificationError(data.verificationError);
          } else {
            setErrorMessage('Verification is required, but no URL was provided.');
          }
        }

        if (data.status === 'manual_review') {
          if (data.verificationSecret) {
            setVerificationUrl(data.verificationSecret);
            setVerificationError(data.verificationError);
          } else {
            setErrorMessage('Verification is required, but no URL was provided.');
          }
        }
      };

      const handleOrderError = (error: { message: string }) => {
        console.error('Received orderError:', error);
        setErrorMessage(error.message);
        setPaymentStatus('failed');
      };

      // Register event listeners
      purchaseSocket.on('orderStatusUpdate', handleOrderStatusUpdate);
      purchaseSocket.on('orderError', handleOrderError);

      // Cleanup event listeners on unmount or when dependencies change
      return () => {
        purchaseSocket.off('orderStatusUpdate', handleOrderStatusUpdate);
        purchaseSocket.off('orderError', handleOrderError);
      };
    }
  }, [purchaseSocket, paymentId]);

  useEffect(() => {
    if (paymentStatus === 'paid') {
      const paymentIntent = new URLSearchParams(window.location.search).get('payment_intent');
      const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
      navigate(
        `/cart/game-activation?payment_intent=${paymentIntent}&payment_intent_client_secret=${clientSecret}`
      );
      dispatch(clearCart());
      dispatch(clearPaymentData());
    } else if (paymentStatus === 'failed') {
      setErrorMessage(paymentError || 'Payment failed, please try again.');
    }
  }, [dispatch, navigate, paymentStatus, paymentError]);

  const handleRetry = async () => {
    const newPaymentIntent = await dispatch(createPaymentIntent({ amount: cartTotal, items: cartItems }));
    if (createPaymentIntent.fulfilled.match(newPaymentIntent)) {
      navigate('/cart/payment');
    } else if (createPaymentIntent.rejected.match(newPaymentIntent)) {
      const error = newPaymentIntent.payload as string;
      setErrorMessage(error);
    }
  };

  return (
    <Box padding={2} textAlign="center">
      {(() => {
        switch (paymentStatus) {
          case 'processing':
            return (
              <>
                <Typography variant="h6" sx={{ mb: 4 }}>
                  Please wait while we confirm your payment...
                </Typography>
                <CircularProgress />
              </>
            );
          case 'pending_verification':
          case 'processing_verification':
            return verificationSecret ? (
              <VerificationStatus verificationSecret={verificationSecret} verificationError={verificationError} paymentStatus={paymentStatus} />
            ) : (
              <Typography variant="h6" sx={{ mb: 4 }}>
                Verification is required, but no URL was provided.
              </Typography>
            );
          case 'manual_review':
            return <ManualReview />;
          case 'failed':
            return (
              <>
                {errorMessage === 'Too many failed requests' ? (
                  <Container
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img src="/img/payment_block.webp" height={250} alt="Too many failed requests" />
                    <Typography variant="h4" sx={{ mb: 3 }}>
                      Too many failed requests
                    </Typography>
                    <Typography variant="h6" sx={{ mb: 3 }}>
                      Please try again later or contact our support.
                    </Typography>
                    <Button
                      startIcon={<ArrowBackRoundedIcon />}
                      variant="contained"
                      color="primary"
                      onClick={() => navigate('/')}
                    >
                      Return Home
                    </Button>
                  </Container>
                ) : (
                  <Container
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img src="/img/payment_error.webp" height={250} alt="Payment Error" />
                    <Typography variant="h4" sx={{ mb: 4 }}>
                      Your payment failed.
                    </Typography>
                    <Typography variant="h6" sx={{ mb: 4 }}>
                      Please try again, or contact our support.
                    </Typography>
                    <Button
                      startIcon={<ArrowBackRoundedIcon />}
                      variant="contained"
                      color="primary"
                      onClick={handleRetry}
                    >
                      Try Again
                    </Button>
                  </Container>
                )}
              </>
            );
          default:
            return (
              <>
                <Typography variant="h6" sx={{ mb: 4 }}>
                  Please wait while we confirm your payment...
                </Typography>
                <CircularProgress />
              </>
            );
        }
      })()}
    </Box>
  );
};

export default PaymentStatus;
