import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import { Card, CardActionArea, CardMedia, Typography, CardHeader, Skeleton, Box } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import PlatformLogo from 'components/icons/PlatformLogos';
import { Link as RouterLink } from 'react-router-dom';
import ProductPriceTag from 'components/ProductPriceTag';
import { getProductImgSm, makeUrlSafe, getTypeName, getPlatformName } from 'utils/getNames';
import FavoritesButton from 'components/FavoritesButton';
import { useFetchPlatformsQuery, useFetchTypesQuery } from 'redux/RTK/optionsApi';

interface ProductProductProps {
  productId: string;
  title: string;
  type: string;
  productPlatformId: string;
  regionId?: string;
  category: string;
  platform: string;
  price: number | string;
  editionName?: string;
  loading?: boolean;
  withFav?: boolean;
}

const ProductCard: React.FC<ProductProductProps> = ({
  title,
  type,
  productId,
  regionId,
  productPlatformId,
  category,
  platform,
  price,
  editionName,
  withFav
}) => {
  const { data: platforms = [] } = useFetchPlatformsQuery();
  const { data: types = [] } = useFetchTypesQuery();
  const selectedCurrency = useSelector((state: RootState) => state.application.selectedCurrency);

  const [imgError, setImgError] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const giftLink = `/${makeUrlSafe(getTypeName(type, types))}/${makeUrlSafe(category)}/${makeUrlSafe(getPlatformName(platform, platforms))}/${makeUrlSafe(title)}/${makeUrlSafe(editionName)}`;
  const prouctLink = `/${makeUrlSafe(getTypeName(type, types))}/${makeUrlSafe(category)}/${makeUrlSafe(getPlatformName(platform, platforms))}/${makeUrlSafe(title)}`;

  const productCardLink = (type === '3' || type === '4') ? giftLink : prouctLink;

  return (
    <Card sx={{
      transition: 'transform 0.3s ease-in-out',
      '&:hover': {
        transform: 'scale(1.07)',
        zIndex: 100
      },
      position: 'relative'
    }}>
      {withFav && (
        <Box sx={{ position: 'absolute', top: 8, right: 8, zIndex: 10 }}>
          <FavoritesButton icon productId={productId} platformGroupId={productPlatformId} />
        </Box>
      )}
      <CardActionArea component={RouterLink}
        to={productCardLink}
        onClick={scrollToTop}>

        {imgError ? (
          <Box sx={{ position: 'relative', width: '100%', height: 180 }}>
            <Skeleton variant="rectangular" width="100%" height={180} />
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'rgba(0, 0, 0, 0.6)',
            }}>
              <ImageIcon fontSize="large" />
            </Box>
          </Box>
        ) : (
          <Box>
            {regionId && (type === '3' || type === '4') &&  (
              <Box
                sx={{
                  position: 'absolute',
                  left: 15,
                  top: 10,
                }}
              >
                <PlatformLogo regionId={regionId} />
              </Box>
            )}

            <CardMedia
              component="img"
              height="180"
              image={getProductImgSm(productId)}
              alt={title}
              onError={() => {
                console.error('Image failed to load:', getProductImgSm(productId));
                setImgError(true);
              }}
            />
          </Box>
        )}
        <CardHeader
          sx={{ minHeight: '102.23px' }}
          avatar={<PlatformLogo platformId={platform} sx={{ width: '40px'}} />}

          title={
            <Typography component="p" variant="subtitle2">
              {title}  {(type === '3' || type === '4') && (
                <>
                  {editionName} {selectedCurrency}
                </>
              )}
            </Typography>
          }
          subheader={<ProductPriceTag variant='h6' productTagPrice={(price || '').toString()} />}
        />
      </CardActionArea>
    </Card>
  );
};

export default ProductCard;