import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import { setFilter } from 'redux/auth/authSlice';
import { FilterValues } from 'redux/types/ProductsTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextField, MenuItem, Grid2 as Grid, InputAdornment } from '@mui/material';
import { Category, Platform, Type, Genre } from 'redux/types/dataTypes';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import PlatformLogo from 'components/icons/PlatformLogos';
import CategoryLogo from '../../../components/icons/CategoryLogos';
import { currencyMapping } from 'utils/currencyMaping';

interface ProductFilterProps {
  filter: FilterValues;
  categories: Category[];
  platforms: Platform[];
  types: Type[];
  genres: Genre[];
  maxPrice: string;
}

const ProductFilter: React.FC<ProductFilterProps> = ({ filter, categories, platforms, types, genres }) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const urlPlatformId = queryParams.get('platform');

  const selectedCurrency = useSelector((state: RootState) => state.application.selectedCurrency);
  const [selectedPlatform, setSelectedPlatform] = useState<string>(urlPlatformId || '');
  const [sort, setSort] = useState<string>(filter.sort || 'A-Z');
  const [maxPrice, setMaxPrice] = useState<string>(filter.maxPrice || '');

  useEffect(() => {
    setSelectedPlatform(urlPlatformId || '');
    dispatch(setFilter({ key: 'platform', value: urlPlatformId || '' }));
  }, [dispatch, urlPlatformId]);

  useEffect(() => {
    setSort(filter.sort || 'A-Z');
  }, [filter.sort]);

  const [platformKey, setPlatformKey] = useState(0);
  const [categoryKey, setCategoryKey] = useState(0);
  const [typeKey, setType] = useState(0);
  const [genreKey, setGenre] = useState(0);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'platform') {
      setSelectedPlatform(value);
      setPlatformKey(prevKey => prevKey + 1);
    } else if (name === 'category') {
      setCategoryKey(prevKey => prevKey + 1);
    }
    else if (name === 'type') {
      setType(prevKey => prevKey + 1);
    }
    else if (name === 'genre') {
      setGenre(prevKey => prevKey + 1);
    }
    else if (name === 'maxPrice') {
      setMaxPrice(value);
    }

    dispatch(setFilter({ key: name as keyof FilterValues, value: value === t('none') ? '' : value }));

    const newSearchParams = new URLSearchParams(location.search);
    if (value === t('none') || value === '') {
      newSearchParams.delete(name);
    } else {
      newSearchParams.set(name, value);
    }
    navigate(`${location.pathname}?${newSearchParams.toString()}`, { replace: true });
  };

  const sortedCategories = [...categories].sort((a, b) => a.name.localeCompare(b.name));
  const sortedPlatforms = [...platforms].sort((a, b) => a.name.localeCompare(b.name));
  const sortedTypes = [...types].sort((a, b) => a.name.localeCompare(b.name));
  const sortedGenres = [...genres].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Grid container spacing={2} sx={{ mb: 4, mt: { xs: 6, sm: 0 } }}>
      {/* Category Filter */}
      <Grid size={{ xs: 6, sm: 4, lg: 2 }}>
        <TextField
          key={categoryKey}
          select
          fullWidth
          size="small"
          label={t('category')}
          value={filter.category || ''}
          onChange={handleChange}
          name="category"
        >
          {[{ _id: '', name: t('none') }, ...sortedCategories].map((category) => (
            <MenuItem key={category._id} value={category._id}>
              {category._id && <CategoryLogo categoryId={category._id || '/default-image-url.png'} sx={{ width: '16px', height: '16px', mr: 1 }} />}
              {category.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {/* Platform Filter */}
      <Grid size={{ xs: 6, sm: 4, lg: 2 }}>
        <TextField
          key={platformKey}
          select
          fullWidth
          size="small"
          label={t('platform')}
          value={selectedPlatform}
          onChange={handleChange}
          name="platform"
        >
          {[{ _id: '', name: t('none') }, ...sortedPlatforms].map(platform => (
            <MenuItem key={platform._id} value={platform._id}>
              {platform._id && <PlatformLogo platformId={platform._id || '/default-image-url.png'} sx={{ width: '16px', height: '16px', mr: 1 }} />}
              {platform.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {/* Type Filter */}
      <Grid size={{ xs: 6, sm: 4, lg: 2 }}>
        <TextField
          key={typeKey}
          select
          fullWidth
          size="small"
          label={t('productType')}
          value={filter.type || ''}
          onChange={handleChange}
          name="type"
        >
          {[{ _id: '', name: t('none') }, ...sortedTypes].map((type) => (
            <MenuItem key={type._id} value={type._id}>
              {type.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {/* Genre Filter */}
      <Grid size={{ xs: 6, sm: 4, lg: 2 }}>
        <TextField
          key={genreKey}
          select
          fullWidth
          size="small"
          label={t('genre')}
          value={filter.genre || ''}
          onChange={handleChange}
          name="genre"
        >
          {[{ _id: '', name: t('none') }, ...sortedGenres].map((genre) => (
            <MenuItem key={genre._id} value={genre._id}>
              {genre.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {/* Max Price Filter */}
      <Grid size={{ xs: 6, sm: 4, lg: 2 }}>
        <TextField
          fullWidth
          size="small"
          label={t('maxPrice')}
          value={maxPrice}
          onChange={handleChange}
          name="maxPrice"
          type="number"
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  {currencyMapping[selectedCurrency as keyof typeof currencyMapping]?.symbol &&
                    React.createElement(currencyMapping[selectedCurrency as keyof typeof currencyMapping].symbol, {
                      fontSize: 'small',
                    })}
                </InputAdornment>
              ),
            },
          }}
        />

      </Grid>

      {/* Sort Filter */}
      <Grid size={{ xs: 6, sm: 4, lg: 2 }}>
        <TextField
          select
          fullWidth
          size="small"
          label={t('sort')}
          value={sort}
          onChange={handleChange}
          name="sort"
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <SwapVertIcon fontSize="small" />
                </InputAdornment>
              ),
            },
          }}
        >
          <MenuItem key="a-z" value="A-Z">A-Z</MenuItem>
          <MenuItem key="z-a" value="Z-A">Z-A</MenuItem>
          <MenuItem key="increasing" value="0-9">{t('priceIncreasing')}</MenuItem>
          <MenuItem key="decreasing" value="9-0">{t('priceDecreasing')}</MenuItem>
        </TextField>
      </Grid>
    </Grid>
  );
};

export default ProductFilter;
