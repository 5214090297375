// File: src/axiosSetup.ts (example)
import axios from 'axios';
import { store } from './store'; // your Redux store
import { handleRegionHeadersIfChanged } from './services/locationActions';

const api = axios.create({
  baseURL: '/api', // or your own
});

// Attach response interceptor
api.interceptors.response.use(
  (response) => {
    // 1) Extract relevant headers
    const xCurrency = response.headers['x-currency'];
    const xRegion = response.headers['x-region'];
    const xUserIp = response.headers['x-user-ip'];

    // 2) Pass them to our helper
    handleRegionHeadersIfChanged(store.dispatch, store.getState, {
      'x-currency': xCurrency,
      'x-region': xRegion,
      'x-user-ip': xUserIp,
    });

    return response;
  },
  (error) => {
    // Handle error as needed
    return Promise.reject(error);
  }
);

export default api;