import { Box, Button, Container, Typography, alpha, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PlatformLogo from 'components/icons/PlatformLogos';
import SiteTitle from 'components/SiteTitle';
import ProductPriceTag from 'components/ProductPriceTag';
import { makeUrlSafe, getProductCover } from 'utils/getNames';

interface PlatformInfo {
    imageUrl: string;
    price: number;
    category: string;
    platformName: string;
    regionName: string;
}
interface HomeCoverProps {
    productCoverImg: string;
    heroTitle: string;
    heroType: string;
    productId: string;
    platforms?: PlatformInfo[];
}

export const HomeCover = ({ productCoverImg, heroTitle, heroType, productId, platforms = [] }: HomeCoverProps) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                alignSelf: 'center',
                height: '55vh',
                width: '100%',
                backgroundImage: `url("${getProductCover(productCoverImg)}")`,
                boxShadow:
                    theme.palette.mode === 'light'
                        ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                        : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                '&:after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    top: 0,
                    backgroundImage: theme.palette.mode === 'light'
                        ? `linear-gradient(to top, #c9e3fe, rgba(255, 255, 255, 0))`
                        : `linear-gradient(to top, #02294F, rgba(9, 14, 16, 0))`,
                    zIndex: 1,
                },
                textDecoration: 'none',
            }}
        >
            <Container sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-end',
                height: '100%',
                width: '100%',
                color: 'white',
                zIndex: 2,
            }}>
                <Typography color="text.primary" component="h1" variant="h2" sx={{ display: { xs: 'none', md: 'block' } }}>{heroTitle}</Typography>
                <Typography color="text.primary" component="h1" variant="h5" sx={{ display: { xs: 'block', md: 'none' } }}>{heroTitle}</Typography>

                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    <SiteTitle firstTitle={t('available')} secondTitle={t('now')} variant="h6" color={theme.palette.mode === 'light' ? 'primary' : 'secondary'} />
                </Box>
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    <SiteTitle firstTitle={t('available')} secondTitle={t('now')} variant="h4" color={theme.palette.mode === 'light' ? 'primary' : 'secondary'} />
                </Box>

                <Box display={'flex'} alignItems={'center'} sx={{ gap: 3, mb: 3, ml: { xs: '-7px', md: "-21px" }, flexWrap: 'wrap' }}>
                    {platforms?.map((platform, index) => (
                        <Box key={index}>
                            <Button
                                component={RouterLink}
                                to={`/${makeUrlSafe(heroType)}/${makeUrlSafe(platform.category)}/${makeUrlSafe(platform.platformName)}/${makeUrlSafe(heroTitle)}`}
                                variant="text"
                                size="large"
                                sx={{ padding: { xs: '0 11px', md: "0 21px" }, color: 'text.primary' }}
                                startIcon={<PlatformLogo platformId={platform.imageUrl} sx={{ width: '29px', height: '29px' }} />}
                            >
                                <Box component={'span'} sx={{ display: { xs: 'block', md: 'none' } }}>
                                    <ProductPriceTag variant='h6' productTagPrice={platform.price.toFixed(2)} />
                                </Box>

                                <Box component={'span'} sx={{ display: { xs: 'none', md: 'block' } }}>
                                    <ProductPriceTag variant='h4' productTagPrice={platform.price.toFixed(2)} />
                                </Box>

                            </Button>
                        </Box>
                    ))}
                </Box>
            </Container>
        </Box>
    );
};
