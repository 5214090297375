import React, { useState, useEffect, useRef } from 'react';
import { AppDispatch } from 'redux/store';
import { useDispatch } from 'react-redux';
import { useFetchProductByIdAndPlatformQuery } from 'redux/RTK/productsApi';
import {
    Box,
    Typography,
    Card,
    CardContent,
    IconButton,
    TextField,
    InputAdornment,
    CardMedia,
    Collapse,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useFetchPlatformsQuery, useFetchRegionsQuery } from 'redux/RTK/optionsApi';

import PlatformLogo from 'components/icons/PlatformLogos';
import { getProductImgSm, getPlatformName, getRegionName } from 'utils/getNames';
import { useTranslation } from 'react-i18next';
import { activateKey } from 'redux/key/keyActivationThunk';
import { renderInstructions } from './Instructions/Instructions';

import WarningModal from './WarningModal';
import { keyframes } from '@mui/system';
import ProductKeysAction from './ProductKeysAction';

interface ProductKeysProps {
    productId: string;
    invoiceId: string;
    platformGroupId: string;
    keyCode?: string;
    keyIsRevealed?: boolean;
    isGifted?: string | null | undefined;
    productKeysRef: React.RefObject<HTMLDivElement | null>;
}

// Keyframes for the blinking effect
const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const ProductKeys: React.FC<ProductKeysProps> = ({
    keyCode,
    productId,
    platformGroupId,
    invoiceId,
    keyIsRevealed,
    isGifted,
    productKeysRef,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    // State
    const [showKey, setShowKey] = useState(false);
    const [actualKey, setActualKey] = useState<string>(keyCode || '');
    const [retrievedKey, setRetrievedKey] = useState<string | null>(null);
    const [showInstructions, setShowInstructions] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [platformDetails, setPlatformDetails] = useState<{ platformId: string; region: string } | null>(null);

    const cardRef = useRef<HTMLDivElement>(null);

    // MUI queries
    const { data: platforms = [] } = useFetchPlatformsQuery();
    const { data: regions = [] } = useFetchRegionsQuery();

    // RTK Query to fetch product info
    const { data: productInfo, isLoading, isError } = useFetchProductByIdAndPlatformQuery({
        productId,
        platformId: platformGroupId,
        lang: 'en',
    });

    // 1) **Move state updates to a useEffect** so we only set them
    //    when productInfo changes, not on every render
    useEffect(() => {
        if (productInfo) {
            setTitle(productInfo.title || '');

            setPlatformDetails({
                platformId: productInfo.platformId || '',
                region: productInfo.region,
            });
        }
    }, [productInfo]);

    // 2) Reveal Key
    const handleRevealKey = () => {
        if (keyIsRevealed) {
            dispatch(activateKey({ keyId: actualKey, invoiceId }))
                .unwrap()
                .then((retrievedKey) => {
                    setRetrievedKey(retrievedKey);
                    setShowKey(true);
                })
                .catch((error) => {
                    console.error('Failed to retrieve key', error);
                });
            return;
        }
        setModalOpen(true);
    };

    const handleModalConfirm = () => {
        setModalOpen(false);
        dispatch(activateKey({ keyId: actualKey, invoiceId }))
            .unwrap()
            .then((retrievedKey) => {
                setRetrievedKey(retrievedKey);
                setShowKey(true);
            })
            .catch((error) => {
                console.error('Failed to retrieve key', error);
            });
    };

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(retrievedKey || actualKey);
    };

    // 3) Instructions toggle
    const handleToggleInstructions = () => {
        setShowInstructions((prev) => {
            if (prev && productKeysRef.current) {
                const offsetTop = productKeysRef.current.getBoundingClientRect().top + window.pageYOffset - 100;
                window.scrollTo({ top: offsetTop, behavior: 'smooth' });
            } else if (!prev && cardRef.current) {
                const offsetTop = cardRef.current.getBoundingClientRect().top + window.pageYOffset - 100;
                window.scrollTo({ top: offsetTop, behavior: 'smooth' });
            }
            return !prev;
        });
    };

    const handleModalClose = () => setModalOpen(false);

    // 4) Render
    return (
        <>
            <Card ref={cardRef} sx={{ display: 'flex', flexDirection: 'column', marginBottom: 5, width: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <CardMedia
                        component="img"
                        sx={{ width: 250, height: 160, objectFit: 'cover' }}
                        image={getProductImgSm(productId)}
                        alt={title}
                    />
                    <CardContent sx={{ flex: '1 0 auto', maxWidth: '290px', marginRight: 'auto' }}>
                        <Typography
                            variant="h6"
                            sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                flexGrow: 1,
                                minWidth: 0,
                            }}
                        >
                            {title}
                        </Typography>

                        {platformDetails && (
                            <Typography variant="body1" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', mt: 0 }}>
                                <PlatformLogo platformId={platformDetails.platformId} sx={{ width: 24, height: 24, mr: 1 }} />
                                {getPlatformName(platformDetails.platformId, platforms)} -{' '}
                                {getRegionName(platformDetails.region, regions)}
                            </Typography>
                        )}

                        {actualKey === '' ? (
                            <Typography variant="body1" color="text.secondary" sx={{ mt: 3 }}>
                                {t('purchase_review_pending')}
                            </Typography>
                        ) : (
                            <TextField
                                label="Key"
                                variant="outlined"
                                value={showKey ? retrievedKey || actualKey : '•••••-•••••-•••••'}
                                fullWidth
                                margin="normal"
                                disabled={showKey}
                                slotProps={{
                                    input: {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {!showKey && (
                                                    <IconButton
                                                        onClick={handleRevealKey}
                                                        edge="end"
                                                        sx={{ animation: `${blink} 2s infinite`, color: 'secondary.main' }}
                                                    >
                                                        <Visibility />
                                                    </IconButton>
                                                )}
                                                {showKey && (
                                                    <IconButton onClick={handleCopyToClipboard} edge="end">
                                                        <ContentCopyIcon />
                                                    </IconButton>
                                                )}
                                            </InputAdornment>
                                        ),
                                    },
                                }}
                            />
                        )}
                    </CardContent>

                    {actualKey !== '' && (
                        <ProductKeysAction
                            isGifted={isGifted}
                            showKey={showKey}
                            showInstructions={showInstructions}
                            handleToggleInstructions={handleToggleInstructions}
                        />
                    )}
                </Box>

                <Collapse in={showInstructions} timeout="auto" unmountOnExit>
                    <CardContent>
                        {/* IMPORTANT: renderInstructions must be synchronous! */}
                        {renderInstructions({
                            platformDetails,
                            actualKey: retrievedKey || actualKey,
                        }) as React.ReactNode}
                    </CardContent>
                </Collapse>
            </Card>

            {!keyIsRevealed && <WarningModal open={modalOpen} onClose={handleModalClose} onConfirm={handleModalConfirm} />}
        </>
    );
};

export default ProductKeys;