import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { RootState } from 'redux/store';

interface ProductPriceTagProps {
  productTagPrice: string;
  variant: Variant | 'inherit';
  color?: string | 'text.primary';
}

const ProductPriceTag: React.FC<ProductPriceTagProps> = ({
  productTagPrice,
  variant,
  color
}) => {
  const selectedCurrency = useSelector((state: RootState) => state.application.selectedCurrencySymbol);

  return (
    <Typography component="span" variant={variant} color={color} sx={{ display: "flex" }}>
      {productTagPrice} {selectedCurrency}
    </Typography>
  );
};

export default ProductPriceTag;
