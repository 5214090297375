// src/topics/topicsData.ts

import React from 'react';
import {
    Person as PersonIcon,
    ShoppingCart as ShoppingCartIcon,
    TableChart as TableChartIcon,
    ViewList as ViewListIcon,
    Visibility as VisibilityIcon,
    PlayArrow as CheckCircleIcon,
    Spellcheck as SpellcheckIcon,
    History as HistoryIcon,
    Apps as AppsIcon,
    AssignmentInd as AssignmentIndIcon,
    VerifiedUser as VerifiedUserIcon,
  } from '@mui/icons-material';

interface Step {
  icon?: React.ReactElement;
  title?: string;
  description: string;
}

export interface TopicData {
  category: string;
  topic: string;
  content: string;
  steps?: Step[];
  notes?: string;
  faq?: boolean;
}

export const topicsData: TopicData[] = [
  // 1. How to redeem the code?
  {
    category: 'Redeeming Codes',
    topic: 'How to redeem the code?',
    content: 'Follow the steps below to successfully redeem your code:',
    steps: [
      {
        icon: React.createElement(PersonIcon, { color: 'primary' }),
        title: 'Access Your Account',
        description: 'Click on the user icon located at the top right corner of the page.',
      },
      {
        icon: React.createElement(ShoppingCartIcon, { color: 'primary' }),
        title: 'Navigate to Purchases',
        description: 'In the dropdown menu, select the "Purchases" option to view your recent transactions.',
      },
      {
        icon: React.createElement(TableChartIcon, { color: 'primary' }),
        title: 'Select the Relevant Purchase',
        description: 'From the purchases table, select the transaction associated with the key you wish to activate.',
      },
      {
        icon: React.createElement(ViewListIcon, { color: 'primary' }),
        title: 'View Available Keys',
        description: 'Click on the "View Keys" button to display a list of available product keys for activation.',
      },
      {
        icon: React.createElement(VisibilityIcon, { color: 'primary' }),
        title: 'Reveal the Key',
        description: 'Click the eye icon to reveal the key you wish to activate.',
      },
      {
        icon: React.createElement(CheckCircleIcon, { color: 'primary' }),
        title: 'Activate the Key',
        description: 'Click on the "Activate" button and follow the on-screen instructions.',
      },
    ],
    faq: true,
  },

  // 2. Where can I find my codes?
  {
    category: 'Finding Codes',
    topic: 'Where can I find my codes?',
    content: 'Follow the steps below to find your code:',
    steps: [
      {
        icon: React.createElement(PersonIcon, { color: 'primary' }),
        title: 'Access Your Account',
        description: 'Click on the user icon located at the top right corner of the page.',
      },
      {
        icon: React.createElement(ShoppingCartIcon, { color: 'primary' }),
        title: 'Navigate to Purchases',
        description: 'In the dropdown menu, select the "Purchases" option to view your recent transactions.',
      },
      {
        icon: React.createElement(TableChartIcon, { color: 'primary' }),
        title: 'Select the Relevant Purchase',
        description: 'From the purchases table, identify and select the transaction associated with the key you are looking for.',
      },
      {
        icon: React.createElement(ViewListIcon, { color: 'primary' }),
        title: 'View Available Keys',
        description: 'Click on the "View Keys" button to display a list of available product keys for activation.',
      },
    ],
    faq: true,
  },

  // 3. Where can I get an invoice for my purchase?
  {
    category: 'Invoices',
    topic: 'Where can I get an invoice for my purchase',
    content: 'To access an invoice for your purchase, follow the steps below:',
    steps: [
      {
        icon: React.createElement(PersonIcon, { color: 'primary' }),
        title: 'Access Your Account',
        description: 'Click on the user icon located at the top right corner of the page.',
      },
      {
        icon: React.createElement(ShoppingCartIcon, { color: 'primary' }),
        title: 'Navigate to Purchases',
        description: 'In the dropdown menu, select the "Purchases" option to view your recent transactions.',
      },
      {
        icon: React.createElement(TableChartIcon, { color: 'primary' }),
        title: 'Select the Relevant Purchase',
        description: 'From the purchases table, identify and select the transaction associated with the invoice you need.',
      },
      {
        icon: React.createElement(ViewListIcon, { color: 'primary' }),
        title: 'Download Invoice',
        description: 'Click on the "Download Invoice" button to access a PDF copy of your invoice.',
      },
    ],
    faq: true,
  },

  // 4. I have issues activating my code
  {
    category: 'Code Activation Issues',
    topic: 'I have issues activating my code',
    content: "If you're experiencing problems activating your code, please check the following:",
    steps: [
      {
        icon: React.createElement(SpellcheckIcon, { color: 'primary' }),
        title: 'Check for Typos',
        description: 'Ensure the code is entered correctly without typos.',
      },
      {
        icon: React.createElement(HistoryIcon, { color: 'primary' }),
        title: 'Verify Code Usage',
        description: "Verify that the code hasn't been used previously.",
      },
      {
        icon: React.createElement(AppsIcon, { color: 'primary' }),
        title: 'Correct Platform',
        description: "Confirm that you're using the code in the correct platform or application.",
      },
    ],
    notes: 'If the issue persists, please contact our support team for further assistance.',
    faq: true,
  },

  // 5. I have issues with my identity verification
  {
    category: 'Identity Verification',
    topic: 'I have issues with my identity verification',
    content: "If you're facing challenges with identity verification, please ensure that:",
    steps: [
      {
        icon: React.createElement(AssignmentIndIcon, { color: 'primary' }),
        title: 'Clear Documents',
        description: 'Your identification documents are clear and legible.',
      },
      {
        icon: React.createElement(PersonIcon, { color: 'primary' }),
        title: 'Match Personal Info',
        description: 'Your personal information matches the records.',
      },
      {
        icon: React.createElement(VerifiedUserIcon, { color: 'primary' }),
        title: 'Supported Method',
        description: 'You are using a supported verification method.',
      },
    ],
    faq: true,
  },
];
