// authSlice.js
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { googleLogin, authMe, verifyEmail, login, register, logout } from './authThunks';
import { fetchUserInvoices, fetchUserData, fetchUserFavorites } from './fetchUserThunks';
import { addFavorite, removeFavorite, updateUserSettings, updatePassword } from './updateUserThunks';
import { initialState } from './authTypes';
import { FilterValues } from '../types/ProductsTypes';

function isFilterKey(key: any): key is keyof FilterValues {
  return ['category', 'platform', 'type', 'sort', 'genre', 'maxPrice'].includes(key);
}


export const setSearchQuery = (query: string) => ({
  type: 'SET_SEARCH_QUERY',
  payload: query,
});



const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAuthState: (state) => {
      state.user = null;
      state.isLoggedIn = false;
      state.isGoogleLoading = false;
      state.hasMissingInfo = false;
      state.isEmailVerified = false;
      state.identityRequireCheck = false;
      state.welcomePageLoading = false;
      state.error = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    setWelcomePageLoading: (state, action: PayloadAction<boolean>) => {
      state.welcomePageLoading = action.payload;
    },
    setUpdateIdentityRequireCheck: (state, action: PayloadAction<boolean>) => {
      state.identityRequireCheck = action.payload;
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setFilter: (state, action: PayloadAction<{ key: keyof FilterValues; value: string }>) => {
      const { key, value } = action.payload;
      if (isFilterKey(key)) {
        state.filter[key] = value;
      }
    },
    resetFilters: (state) => {
      state.filter = {
        platform: '',
        maxPrice: '',
        type: '',
        category: '',
        genre: '',
        sort: 'A-Z',
      };
    },

  },
  extraReducers: (builder) => {
    builder

      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        const user = action.payload.user;
        state.isLoading = false;
        state.isLoggedIn = true;
        state.user = user;
        state.error = null;
        state.isEmailVerified = action.payload.emailVerified;
        state.identityRequireCheck = action.payload.identityRequireCheck;
        state.hasMissingInfo = !user.firstName || user.firstName === 'Unknown' ||
          !user.lastName || user.lastName === 'Unknown' ||
          !user.birthday || !user.address ||
          !user.city || !user.zip || !user.country;

        state.welcomePageLoading = !state.isEmailVerified || state.hasMissingInfo || state.identityRequireCheck;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(googleLogin.pending, (state) => {
        state.isLoading = true;
        state.isGoogleLoading = false;
        state.error = null;
      })
      .addCase(googleLogin.fulfilled, (state) => {
        state.isLoading = false;
        state.isLoggedIn = true;
        state.isGoogleLoading = true;
        state.isEmailVerified = true;
        state.error = null;
      })
      .addCase(googleLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.isGoogleLoading = false;
        state.error = action.payload as string;
      })
      .addCase(authMe.pending, (state) => {
        state.isGoogleLoading = true;
        state.error = null;
      })
      .addCase(authMe.fulfilled, (state, action) => {
        const user = action.payload.user;
        state.isLoading = false;
        state.isLoggedIn = true;
        state.isGoogleLoading = false;
        state.isEmailVerified = true;
        state.user = user;
        state.identityRequireCheck = action.payload.identityRequireCheck;
        state.hasMissingInfo = !user.firstName || user.firstName === 'Unknown' ||
          !user.lastName || user.lastName === 'Unknown' ||
          !user.birthday || !user.address ||
          !user.city || !user.zip || !user.country;

        state.welcomePageLoading = !state.isEmailVerified || state.hasMissingInfo || state.identityRequireCheck;
        state.error = null;
      })
      .addCase(verifyEmail.pending, (state) => {
        state.isLoading = true;
        state.error = null; // Clear the error on a new request
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isEmailVerified = true;
        state.error = null;
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoading = false;
        state.user = null;
        state.isLoggedIn = false;
        state.error = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(register.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(addFavorite.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addFavorite.fulfilled, (state, action) => {
        state.isLoading = false;
        if (state.user) {
          state.user.favorites = action.payload.favorites;
        }
        state.error = null;
      })
      .addCase(addFavorite.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(removeFavorite.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeFavorite.fulfilled, (state, action) => {
        state.isLoading = false;
        if (state.user) {
          state.user.favorites = state.user.favorites.filter(fav => fav.platformGroupId !== action.meta.arg);
        }
        state.error = null;
      })
      .addCase(removeFavorite.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchUserInvoices.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserInvoices.fulfilled, (state, action) => {
        state.isLoading = false;
        if (state.user) {
          state.user.invoices = action.payload;
        }
        state.error = null;
      })
      .addCase(fetchUserInvoices.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(updateUserSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateUserSettings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = { ...state.user, ...action.payload };
        state.hasMissingInfo = false;
        state.error = null;
      })
      .addCase(updateUserSettings.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.user = action.payload;
      })
      .addCase(updatePassword.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updatePassword.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchUserFavorites.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserFavorites.fulfilled, (state, action) => {
        state.isLoading = false;
        if (state.user) {
          state.user.favorites = action.payload;
        }
        state.error = null;
      })
      .addCase(fetchUserFavorites.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  }
});

export const {
  clearAuthState,
  clearError,
  setWelcomePageLoading,
  setUpdateIdentityRequireCheck,
  setLanguage,
  setFilter,
  resetFilters,
} = authSlice.actions;

export default authSlice.reducer;